@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-dropdown {
  max-width: 90vw !important;
  overflow-x: auto !important;
}

.ant-cascader-menus {
  width: fit-content !important;
}
@media (max-width: 900px) {
  .ant-select,
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}
